import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import { FaTwitter, FaFacebook, FaLink } from "react-icons/fa";
import { Layout, PostCard } from "../components/common";
import { MetaData } from "../components/common/meta";

/**
 * Author page (/author/:slug)
 *
 * Loads all posts for the requested author incl. pagination.
 *
 */
const Author = ({ data, location }) => {
    const author = data.ghostAuthor;
    console.log({ author });
    const posts = data.allGhostPost.edges;

    return (
        <>
            <MetaData data={data} location={location} type="profile" />
            <Layout>
                <div className="container">
                    <div className="archive-section">
                        <div className="archive-image global-image">
                            {author.profile_image && (
                                <img
                                    src={author.profile_image}
                                    alt={author.name}
                                />
                            )}
                        </div>

                        <h1 className="archive-title">{author.name}</h1>
                        <span className="archive-details"></span>
                        <div className="archive-icons">
                            {author.website && (
                                <a
                                    href={author.website}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    aria-label="link Website"
                                >
                                    <FaLink />
                                </a>
                            )}
                            {author.facebook && (
                                <a
                                    href={`https://www.facebook.com/${author.facebook}`}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    aria-label="link Facebook"
                                >
                                    <FaFacebook />
                                </a>
                            )}
                            {author.twitter && (
                                <a
                                    href={`https://twitter.com/${author.twitter}`}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    aria-label="link Twitter"
                                >
                                    <FaTwitter />
                                </a>
                            )}
                            {author.bio && (
                                <p className="archive-description">
                                    {author.bio}
                                </p>
                            )}
                        </div>
                    </div>
                    <div className="loop-section">
                        <section className="loop-wrap">
                            {posts.map(({ node }, index) => (
                                <PostCard
                                    index={index}
                                    key={node.id}
                                    post={node}
                                />
                            ))}
                        </section>
                    </div>
                </div>
            </Layout>
        </>
    );
};

Author.propTypes = {
    data: PropTypes.shape({
        ghostAuthor: PropTypes.shape({
            name: PropTypes.string.isRequired,
            cover_image: PropTypes.string,
            profile_image: PropTypes.string,
            website: PropTypes.string,
            bio: PropTypes.string,
            location: PropTypes.string,
            facebook: PropTypes.string,
            twitter: PropTypes.string,
        }),
        allGhostPost: PropTypes.object.isRequired,
    }).isRequired,
    location: PropTypes.shape({
        pathname: PropTypes.string.isRequired,
    }).isRequired,
    pageContext: PropTypes.object,
};

export default Author;

export const pageQuery = graphql`
    query GhostAuthorQuery($slug: String!, $limit: Int!, $skip: Int!) {
        ghostAuthor(slug: { eq: $slug }) {
            ...GhostAuthorFields
        }
        allGhostPost(
            sort: { order: DESC, fields: [published_at] }
            filter: { authors: { elemMatch: { slug: { eq: $slug } } } }
            limit: $limit
            skip: $skip
        ) {
            edges {
                node {
                    ...GhostPostFields
                }
            }
        }
    }
`;
